import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageBanner from '../components/PageBanner';
import BioGrid from '../components/BioGrid';

const SolutionsPage = ({ data: { prismicStaffPage, bios } }) => {
  const { data } = prismicStaffPage;
  return (
    <Layout headerColor={data.initial_header_colour}>
      <SEO title="Our Team" keywords={['Low Doc Loans', 'Development Finance', 'Construction Finance', 'Bridging Finance', 'Non Bank Loans', 'New Build Finance']} />
      <div className="header-spacer-mobile" />
      <PageBanner bannerImage={data.banner_image} />
      <h2 className="blue center mt6-l mb6-l mt5 mb5 f2 f1-l tc">{data.title.text}</h2>
      <BioGrid input={bios} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    prismicStaffPage {
      data {
        banner_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        initial_header_colour
        title {
          text
        }
      }
    }
    bios: allPrismicStaffBio(sort: {fields: [data___weight], order: DESC}) {
      edges {
        node {
          id
          uid
          data {
            name {
              text
            }
            phone_number {
              text
            }
            email {
              text
            }
            position {
              text
            }
            bio_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1800, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            portal_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1800, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            weight
          }
        }
      }
    }
  }
`;

export default SolutionsPage;
