import React from 'react';
import PropTypes from 'prop-types';
import BioCard from '../components/BioCard';

const BioGrid = ({input}) => (
  <div className="card-grid mt3 mb6 flex-l flex-wrap justify-between pl3 pr3 pl5-l pr5-l">
    {input.edges.map(bioItem => (
      <BioCard input={bioItem.node} classNames="w-100 w-30-l mb4" />
    ))}
  </div>
);
export default BioGrid;

BioGrid.propTypes = {
  input: PropTypes.object.isRequired
};
